<template>
  <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      @submit.prevent="submit(challengeId)"
      :disabled="readOnlyMode"
  >

    <v-card class="rounded-lg mb-8">
        <v-card-title>
            Our Secret Message
        </v-card-title>
        <v-card-text>
            <div class="mb-4 subtitle-1">
                <strong>Why you may want to choose this activity</strong><br>
            </div>
            <div>
                <p>
                Breaking codes, discovering hidden messages, deciphering symbols... This sneaky activity requires a small amount of effort including the uploading of a photo and caption. You’ll write a secret message for your guests, which will be converted into a secret code which the guests can decipher using the symbols on the page in the booklet. You can of course write any message you wish. However, whether your guests can crack your code and uncover the message is a different story.
                </p>

            <img
              class="mb-4"
              style="max-width: 600px; width: 100%;"
              src="@/assets/img/activity_d_our_secret_message.jpg"
              alt="Image shape"
            />

            </div>
        </v-card-text>
    </v-card>

    <v-card>
          <v-card-text>
              <div class="mb-4 subtitle-1">
                  <strong>How to build this activity</strong><br>
              </div>
              <div class="text-primary">
                    <p>Thinking caps on please. What we need here is for you to think of a fun message that you would like to pass on to your guests. It can’t be too long otherwise people will still be trying to solve it long after you have left for your honeymoon! 2 or 3 sentences at the most. Simply type your words in the box below and the message will be converted into the secret code.</p>
                    <p>You also need to upload a photo and a caption for this activity page.</p>
                    <p>Example: <em>We doubt you can crack this code in less than five minutes. And we reckon we know which ones of you will be the quickest. 
                      Prove us wrong or right. Love, Lewis and Charlotte.</em></p>

                      
              </div>
          </v-card-text>

              <div class="pa-4">

              

              <v-textarea
                  v-model="message"
                  :rules="secretMessageRules"
                  label="Secret Message"
                  counter="250"
                  outlined
                  @change="fieldValueChanged"
              ></v-textarea>

              <p>
                The image below is the shape of the photo required.
              </p>
              <img
                class="mb-4"
                style="max-width: 350px; width: 100%;"
                src="@/assets/img/photo_sizing_w92mm_h85mm.jpg"
                alt="Image shape"
              />  


              <image-upload
                  ref="imageUpload"
                  sourceType="challenge"
                  :sourceId=challengeId
              />

              <v-text-field
                  v-model="caption"
                  :rules="captionRules"
                  label="Write a caption for this photo"
                  counter="65"
                  outlined
                  class="mt-8"
                  @change="fieldValueChanged"
              >
              </v-text-field>

              <div class="mt-8">
                      <v-btn
            :disabled="!valid || readOnlyMode"
            type="submit"
            color="green"
            class="mr-4"
        >
          Save
        </v-btn>
              <v-btn :disabled="readOnlyMode" @click="clear" class="mr-4">Clear</v-btn>
              <v-btn :disabled="!valid" @click="preview(previewOptions)">Preview</v-btn>
              </div>
            </div>
      </v-card>

  </v-form>
</template>

<script>
import ImageUpload from "@/components/ImageUpload";
import {challenge} from "@/mixins/challenge";
import {form} from "@/mixins/form";

export default {
  name: "ChallengeD",
  components: { ImageUpload },
  mixins: [challenge, form],
  data: () => ({
    challengeId: "d",
  }),
  computed: {
    caption: {
      get: function () {
        return this.$store.state.order.activities[3].data.caption
      },
      set(value) {
        this.$store.commit("setActivity", {
          challengeId: this.challengeId,
          caption: value
        })
      }
    },
    message: {
      get: function () {
        return this.$store.state.order.activities[3].data.message
      },
      set(value) {
        this.$store.commit("setActivity", {
          challengeId: this.challengeId,
          message: value
        })
      }
    },
  }
}
</script>
