<template>
  <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      @submit.prevent="submit(challengeId)"
      :disabled="readOnlyMode"
  >

    <v-card class="rounded-lg mb-8">
        <v-card-title>
            The Happy Couple
        </v-card-title>
        <v-card-text>
            <div class="mb-4 subtitle-1">
                <strong>Why you may want to choose this activity</strong><br>
            </div>
            <div>
                <p>
                A chance to make your own 'Mr & Mrs', ‘Mr & Mr’, ‘Ms & Ms’ etc. quiz. We’ve probably all seen or at least know how this type of quiz on TV works.
                </p>
                <p>
                Here is your chance to make one for yourselves, but the question is, how well do your guests know you? This activity will require some effort. You’ll need to think of six fun questions about yourselves, where the answer is one or the other of you. The questions can be as sensible or wacky as you wish! Not only will your guests have some fun but they will also learn a little bit about you.
                </p>

                <img
                  class="mb-4"
                  style="max-width: 600px; width: 100%;"
                  src="@/assets/img/activity_b_the_happy_couple.jpg"
                  alt="Image shape"
                />

            </div>
        </v-card-text>
    </v-card>

    <v-card>
        <v-card-text>
            <div class="mb-4 subtitle-1">
                <strong>How to build this activity</strong><br>
            </div>
            <div class="text-primary">
              <p>
              For this activity we will need 6 questions about yourselves that not many other people would know the answer to. 
              The answer to each question needs to be one or the other of you. Questions should be along the lines of “Which one of us ………..?” 
              For example: Which one of us fell asleep on the train and ended up in Glasgow instead of Lancaster?
              </p>

              <p>
              The image below is the shape of the photo required.
              </p>
            </div>
        </v-card-text>



        <div class="pa-4">

            <img
              class="mb-4"
              style="max-width: 350px; width: 100%;"
              src="@/assets/img/photo_sizing_w92mm_h85mm.jpg"
              alt="Image shape"
            />

            <image-upload
                ref="imageUpload"
                sourceType="challenge"
                :sourceId=challengeId
                class="mb-6"
            />

            <v-text-field
                v-model="caption"
                :rules="captionRules"
                label="Write a caption for this photo"
                counter="65"
                outlined
                class="mb-8"
                @change="fieldValueChanged"
            >
            </v-text-field>

            <ol>
            <li v-for="qAndA in questionsAndAnswers">
                <v-text-field
                    v-model="qAndA.question"
                    :rules="question120Rules"
                    label="Question"
                    counter="120"
                    outlined
                    @change="fieldValueChanged"
                >
                </v-text-field>
                <v-select
                    v-model="qAndA.answer"
                    :rules="answerRules"
                    :items="answerItems"
                    label="Answer"
                    dense
                    outlined
                    @change="fieldValueChanged"
                ></v-select>
            </li>
            </ol>

            <div class="mt-8">
                    <v-btn
            :disabled="!valid || readOnlyMode"
            type="submit"
            color="green"
            class="mr-4"
        >
          Save
        </v-btn>
            <v-btn :disabled="readOnlyMode" @click="clear" class="mr-4">Clear</v-btn>
            <v-btn :disabled="!valid" @click="preview(previewOptions)">Preview</v-btn>
            </div>
        </div>
    </v-card>

  </v-form>
</template>

<script>
import ImageUpload from "@/components/ImageUpload";
import {challenge} from "@/mixins/challenge";
import {form} from "@/mixins/form";

export default {
  name: "ChallengeB",
  components: { ImageUpload },
  mixins: [challenge, form],
  data: () => ({
    challengeId: "b",
    selectedChallenge: {},
  }),
  computed: {
    answerItems() {
      return [
        this.$store.state.order.generalData.name_1,
        this.$store.state.order.generalData.name_2,
      ]
    },
    caption: {
      get: function () {
        return this.$store.state.order.activities[1].data.caption
      },
      set(value) {
        this.$store.commit("setActivity", {
          challengeId: this.challengeId,
          caption: value
        })
      }
    },
    questionsAndAnswers: {
      get: function () {
        return this.$store.state.order.activities[1].data.questionsAndAnswers
      },
      // Note no corresponding set(). See `questionsAndAnswers` watch option.
    },
  },
  watch: {
    questionsAndAnswers: {
      handler: function (value) {
        this.$store.commit("setActivity", {
          challengeId: this.challengeId,
          questionsAndAnswers: value
        })
      },
      deep: true
    }
  },
}
</script>
