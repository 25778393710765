<template>
  <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      @submit.prevent="submit(challengeId)"
      :disabled="readOnlyMode"
  >


    <v-card class="rounded-lg mb-8">
        <v-card-title>
            Popping the Question
        </v-card-title>
        <v-card-text>
            <div class="mb-4 subtitle-1">
                <strong>Why you may want to choose this activity</strong><br>
            </div>
            <div>
                <p>
                You need to think of 5 questions about yourselves where the answer to each question is a number. These 5 answers will be put on balloons on the page in the booklet, along with an extra number which was not an answer to one of your questions. The guests’ job is to work out which balloon is left by popping the 5 balloons containing the correct answers to your questions. It’s actually a lot simpler than it sounds, and it’ll have your guests questioning their choices and debating with each other before heading to the answers.
                </p>

            <img
              class="mb-4"
              style="max-width: 600px; width: 100%;"
              src="@/assets/img/activity_l_popping_the_question.jpg"
              alt="Image shape"
            />

            </div>
        </v-card-text>
    </v-card>

    <v-card>
          <v-card-text>
              <div class="mb-4 subtitle-1">
                  <strong>How to build this activity</strong><br>
              </div>
              <div class="text-primary">
                <p>For this activity think up 5 questions about yourselves where the answers are all numbers, in fact, all different numbers. Questions like “How many dogs have we owned as a couple” or “How many speeding fines have we received as a combined total?”. We then want you to think of a 6th number (with no question) that doesn’t match any of the 5 answers, and these 6 numbers will then be placed on balloons in the activity page, along with your 5 questions. If your guests answer each question correctly, they ‘pop’ the balloons with the correct answers leaving the balloon containing your 6th number.</p>
                <p>You also need to upload a photo and caption for this activity.</p>
              </div>
          </v-card-text>


        <div class="pa-4">

              <p>
                The image below is the shape of the photo required.
              </p>
              <img
                class="mb-4"
                style="max-width: 350px; width: 100%;"
                src="@/assets/img/photo_sizing_w92mm_h85mm.jpg"
                alt="Image shape"
              />      

            <image-upload
                ref="imageUpload"
                sourceType="challenge"
                :sourceId=challengeId
            />

            <v-text-field
                v-model="caption"
                :rules="captionRules"
                label="Write a caption for this photo"
                counter="65"
                outlined
                class="mt-8"
                @change="fieldValueChanged"
            >
            </v-text-field>

            <ol>
                <li v-for="qAndA in questionsAndAnswers">
                    <v-text-field
                        v-model="qAndA.question"
                        :rules="question110Rules"
                        label="Question"
                        counter="110"
                        outlined
                        @change="fieldValueChanged"
                    />
                    <v-text-field
                        v-model="qAndA.answer"
                        :rules="[...ptqAnswerRules, answerIsUnique]"
                        label="Answer"
                        outlined
                        @change="fieldValueChanged"
                    />
                </li>
              <li>
                <v-text-field
                    v-model="redHerring"
                    :rules="[...ptqAnswerRules, answerIsUnique]"
                    label="Answer (red herring)"
                    outlined
                    @change="fieldValueChanged"
                />
              </li>
            </ol>

            <div class="mt-8">
                        <v-btn
            :disabled="!valid || readOnlyMode"
            type="submit"
            color="green"
            class="mr-4"
        >
          Save
        </v-btn>
                <v-btn :disabled="readOnlyMode" @click="clear" class="mr-4">Clear</v-btn>
                <v-btn :disabled="!valid" @click="preview(previewOptions)">Preview</v-btn>
            </div>
        </div>
    </v-card>

  </v-form>
</template>

<script>
import {challenge} from "@/mixins/challenge";
import {form} from "@/mixins/form";
import ImageUpload from "@/components/ImageUpload";

export default {
  name: "ChallengeL",
  components: { ImageUpload },
  mixins: [challenge, form],
  data: () => ({
    challengeId: "l",
    ptqAnswerRules: [
      v => !!v || 'Answer is required',
      v => /\d/.test(v) || 'Answer must be a number',
    ],
    selectedChallenge: {}
  }),
  methods: {
    answerIsUnique(v) {
      // Count how many matches we get.
      // 1 match is expected as Vue will have already written the current field's value to the $store.
      // 2 or more matches means that the user has entered duplicate answers.
      let matchCount = 0
      // Compare current field's value with each regular answer in the $store.
      this.questionsAndAnswers.forEach(qAndA => {
        if (qAndA.answer !== "" && qAndA.answer !== null) {
          // console.log(`Comparing ${v} with ${qAndA.answer}`)
          if (v === qAndA.answer) {
            // console.log("MATCH")
            matchCount++
          }
        }
      })
      // Compare current field's value with the red herring answer (Answer #6).
      // console.log(`Comparing ${v} with ${this.$store.state.order.activities[11].data.agb}`)
      if (v === this.$store.state.order.activities[11].data.agb) {
        // console.log("MATCH")
        matchCount++
      }
      if (matchCount >= 2) {
        // console.log("matchCount:", matchCount)
        return 'Answers must be unique'
      } else {
        return true
      }
    }
  },
  computed: {
    caption: {
      get: function () {
        return this.$store.state.order.activities[11].data.caption
      },
      set(value) {
        this.$store.commit("setActivity", {
          challengeId: this.challengeId,
          caption: value
        })
      }
    },
    questionsAndAnswers: {
      get: function () {
        return this.$store.state.order.activities[11].data.questionsAndAnswers
      },
      // Note no corresponding set(). See `questionsAndAnswers` watch option.
    },
    redHerring: {
      get: function () {
        return this.$store.state.order.activities[11].data.agb
      },
      set(value) {
        this.$store.commit("setActivity", {
          challengeId: this.challengeId,
          redHerring: value
        })
      }
    }
  },
  watch: {
    questionsAndAnswers: {
      handler: function (value) {
        this.$store.commit("setActivity", {
          challengeId: this.challengeId,
          questionsAndAnswers: value
        })
      },
      deep: true
    }
  },
}
</script>
