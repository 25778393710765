<template>
  <v-select
      v-model="selectedChallenge"
      :items=updatedChallengeItems
      :label=selectListLabel
      @change="onChange($event)"
      :disabled="readOnlyMode"
      dense
      outlined
  ></v-select>
</template>

<script>
import {form} from "@/mixins/form";
import {page} from "@/mixins/page";

export default {
  name: "ChallengeSelector",
  mixins: [
      form,
      page
  ],
  data: () => ({
    initialChallengeItems: [
      {
        text: "Love Letters",
        value: "a"
      },
      {
        text: "The Happy Couple",
        value: "b"
      },
      {
        text: "Round the World Wedding Quiz",
        value: "c"
      },
      {
        text: "Our Secret Message",
        value: "d"
      },
      {
        text: "Say What You See",
        value: "e"
      },
      {
        text: "Limerick",
        value: "f"
      },
      {
        text: "Words of Wisdom",
        value: "g"
      },
      {
        text: "Find the Guest",
        value: "h"
      },
      {
        text: "Wedding Speech Bingo",
        value: "i"
      },
      {
        text: "True or False?",
        value: "j"
      },
      {
        text: "Picture This",
        value: "k"
      },
      {
        text: "Popping the Question",
        value: "l"
      },
      {
        text: "Where Were We?",
        value: "m"
      },
    ],
    selectedChallenge: {}
  }),
  computed: {
    updatedChallengeItems() {
      // Ensure that the returned array excludes challenges that have already been mapped to pages.

      const pageChallengeMap = this.$store.state.pageChallengeMap
      // Clone initialChallengeItems array as a starting point.
      let returnArray = [...this.initialChallengeItems]

      // Loop through pages that may have challenges mapped to them.
      for (const key in pageChallengeMap) {
        // Loop though potential challenge items in the return array.
        returnArray.forEach(challengeItem => {
          if (pageChallengeMap[key] === challengeItem.value) {
            // If there's a match, remove that challenge item from the return array.
            // Get index of item in array.
            const challengeItemIndex = returnArray.indexOf(challengeItem)
            // Remove item at that index from array.
            returnArray.splice(challengeItemIndex, 1)
          }
        })
      }

      // Return the updated list of challenge items.
      return returnArray
    },
    selectListLabel() {
      const selectedChallengeId = this.$store.state.pageChallengeMap[this.currentPageNum]
      if (typeof selectedChallengeId !== "undefined" && selectedChallengeId !== "") {
        // Return the selected challenge name if one has been selected.
        const selectedChallengeObj = this.initialChallengeItems.find(o => o.value === selectedChallengeId)
        return selectedChallengeObj.text
      }
      return `Page ${String(this.currentPageNum)} Activity`
    },

  },
  methods: {
    onChange(event) {
      // Map the selected challenge to the current page number in Vuex.
      this.$store.commit("mapChallengeToPage", {
        challengeId: event,
        pageNumInternal: this.currentPageNum
      })
      // Mark the Absent Friends page as incomplete if activity "h" or "i" is selected.
      if (this.$store.state.absentFriends) {
        if (event === "h" || event === "i") {
          this.$store.state.pages[13].complete = false
        }
      }
    }
  }
}
</script>
