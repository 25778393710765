<template>
  <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      @submit.prevent="submit(challengeId)"
      :disabled="readOnlyMode"
  >

    <v-card class="rounded-lg mb-8">
        <v-card-title>
            Find the Guest
        </v-card-title>
        <v-card-text>
            <div class="mb-4 subtitle-1">
                <strong>Why you may want to choose this activity</strong><br>
            </div>
            <div>
                <p>
                The ultimate ice-breaker, requiring your guests to speak to as many other guests as they can. Points can be won for identifying guests that match the criteria on the page, from finding guests who have climbed mountains, to ones with a certain number of siblings. It is a great way of getting guests who don’t know each other to mingle and talk to each other, just watch out for that one relative who will tell you they’ve done everything - everyone has one! 
                </p>

            <img
              class="mb-4"
              style="max-width: 600px; width: 100%;"
              src="@/assets/img/activity_h_find_the_guest.jpg"
              alt="Image shape"
            />


            </div>
        </v-card-text>
    </v-card>

    <v-card>
          <v-card-text>
              <div class="mb-4 subtitle-1">
                  <strong>How to build this activity</strong><br>
              </div>
              <div class="text-primary">
                  <p>
		              Nicely chosen, it’s always fun watching your guests trying to match fellow guests with the criteria. Don’t forget to give this one a try yourself on the day, if you fancy it! All we need from you for this activity are a photo and a caption.
			            </p>
              </div>
          </v-card-text>



        <div class="pa-4">

              <p>
                The image below is the shape of the photo required.
              </p>
              <img
                class="mb-4"
                style="max-width: 350px; width: 100%;"
                src="@/assets/img/photo_sizing_w92mm_h85mm.jpg"
                alt="Image shape"
              />      

            <image-upload
                ref="imageUpload"
                sourceType="challenge"
                :sourceId=challengeId
            />

            <v-text-field
                v-model="caption"
                :rules="captionRules"
                label="Write a caption for this photo"
                counter="65"
                outlined
                class="mt-8"
                @change="fieldValueChanged"
            >
            </v-text-field>

            <div class="mt-8">
                        <v-btn
            :disabled="!valid || readOnlyMode"
            type="submit"
            color="green"
            class="mr-4"
        >
          Save
        </v-btn>
                <v-btn :disabled="readOnlyMode" @click="clear" class="mr-4">Clear</v-btn>
                <v-btn :disabled="!valid" @click="preview">Preview</v-btn>
            </div>
        </div>
    </v-card>

  </v-form>
</template>

<script>
import {form} from "@/mixins/form";
import ImageUpload from "@/components/ImageUpload";

export default {
  name: "ChallengeH",
  components: { ImageUpload },
  mixins: [form],
  data: () => ({
    challengeId: "h",
  }),
  computed: {
    caption: {
      get: function () {
        return this.$store.state.order.activities[7].data.caption
      },
      set(value) {
        this.$store.commit("setActivity", {
          challengeId: this.challengeId,
          caption: value
        })
      }
    },
  }
}
</script>
