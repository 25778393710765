export default function generateAnagram(inputString) {

    // Trim any whitespace from both ends of input string.
    inputString = inputString.trim()

    // Remove all non-alphanumeric characters (excluding whitespace) from input string.
    // Credit: https://stackoverflow.com/a/9364527/464066
    inputString = JSON.stringify(inputString).replace(/[^0-9a-z\s]/gi, '')

    // Split words into array.
    const words = inputString.split(" ")

    /*
    * Calculate character count.
    * Submitted order object apparently requieres the following:
    * Assign an empty string for single word answers.
    * Assign a stringified sort-of-array of numbers for multi-word answers.
    * E.g. "(4,3)"
    * */
    let charCountString = ""
    if (words.length > 1) {
        let charCountArray = []
        words.forEach(word => {
            charCountArray.push(word.length)
        })
        charCountString = "(" + charCountArray.join(",") + ")"
    }

    // Split array of words into nested arrays of letters.
    let lettersByWord = []
    words.forEach(word => {
        lettersByWord.push(word.split(""))
    })

    // Randomise order of letter items in each word array.
    lettersByWord.forEach(word => {
        for (let i = word.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const tmp = word[i];
            word[i] = word[j];
            word[j] = tmp;
        }
    })

    // Prepare to return randomised result.
    let returnTempArray = []
    let returnString

    // if (lettersByWord.length > 1) {
    //     // Multi-word answer.
    //     // Loop through each word and join letter items back into word strings.
    //     lettersByWord.forEach((word, index) => {
    //         returnTempArray.push(word.join(""))
    //     })
    //     // Join word strings back into a single string separated by spaces.
    //     returnString = returnTempArray.join(" ")
    // } else {
    //     // Single-word answer.
    //     // Join letter items back into word string.
    //     returnString = lettersByWord[0].join("")
    // }

    // Loop through each word and join letter items back into word strings.
    lettersByWord.forEach(word => {
        returnTempArray.push(word.join(""))
    })
    // Join word strings back into a single string.
    returnString = returnTempArray.join("")

    return {
        // Transform to uppercase on return.
        anagram: returnString.toUpperCase(),
        charCount: charCountString
    }
}
