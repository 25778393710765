<template>
  <div class="pa-2">
    <page-card
        :cardTitle="computedPageTitle"
        cardTitleIcon="mdi-playlist-edit"
        cardSubtitle="Select your activity"
    >

    <v-card-text>
      <p>Use the drop down list to choose and learn more about each activity.</p>
    </v-card-text>

      <challenge-selector
          :key="currentPage.bookletPageNum"
      />

      <keep-alive>
        <component
            v-bind:is="currentChallengeComponent"
            :key="currentChallengeKey"
            class="pa-2"
        />
      </keep-alive>

      <!-- Debugging -->
<!--      <challenge-l/>-->

    </page-card>

  </div>
</template>


<script>
import ChallengeA from "@/components/Challenges/ChallengeA";
import ChallengeB from "@/components/Challenges/ChallengeB";
import ChallengeC from "@/components/Challenges/ChallengeC";
import ChallengeD from "@/components/Challenges/ChallengeD";
import ChallengeE from "@/components/Challenges/ChallengeE";
import ChallengeF from "@/components/Challenges/ChallengeF";
import ChallengeG from "@/components/Challenges/ChallengeG";
import ChallengeH from "@/components/Challenges/ChallengeH";
import ChallengeI from "@/components/Challenges/ChallengeI";
import ChallengeJ from "@/components/Challenges/ChallengeJ";
import ChallengeK from "@/components/Challenges/ChallengeK";
import ChallengeL from "@/components/Challenges/ChallengeL";
import ChallengeM from "@/components/Challenges/ChallengeM";
import ChallengeSelector from "@/components/ChallengeSelector";
import PageCard from "@/components/PageCard";
import {page} from "@/mixins/page";

export default {
  name: "ChallengePage",
  mixins: [page],
  components: {
    ChallengeA,
    ChallengeB,
    ChallengeC,
    ChallengeD,
    ChallengeE,
    ChallengeF,
    ChallengeG,
    ChallengeH,
    ChallengeI,
    ChallengeJ,
    ChallengeK,
    ChallengeL,
    ChallengeM,
    ChallengeSelector,
    PageCard
  },
  computed: {
    currentChallengeKey() {
      return this.$store.state.pageChallengeMap[this.currentPage.bookletPageNum]
    },
    currentChallengeComponent() {
      const mappedChallenge = this.$store.state.pageChallengeMap[this.currentPage.bookletPageNum]
      if (mappedChallenge !== "") {
        return "Challenge" + mappedChallenge.toUpperCase()
      }
      return ""
    },
    computedPageTitle() {
      return "Page " + this.currentPage.bookletPageNum
    },
  }
}
</script>
