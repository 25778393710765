<template>
  <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      @submit.prevent="submit(challengeId)"
      :disabled="readOnlyMode"
  >

    <v-card class="rounded-lg mb-8">
        <v-card-title>
            Picture This
        </v-card-title>
        <v-card-text>
            <div class="mb-4 subtitle-1">
                <strong>Why you may want to choose this activity</strong><br>
            </div>
            <div>
                <p>
                One of our favourites, this creative game sees you write down a brief description of a scene or place that is very special to you both. Your guests are then encouraged to draw this scene in the frame on the page. Drawing talents are absolutely not required, the less talented ones often make for the more hilarious drawings!
                </p>

            <img
              class="mb-4"
              style="max-width: 600px; width: 100%;"
              src="@/assets/img/activity_k_picture_this.jpg"
              alt="Image shape"
            />

            </div>
        </v-card-text>
    </v-card>

    <v-card>
          <v-card-text>
              <div class="mb-4 subtitle-1">
                  <strong>How to build this activity</strong><br>
              </div>
              <div class="text-primary">
                <p>For this activity, you need to take a little trip down memory lane and choose a place or location that it is important to you as a couple; a memory that you would like to get your guests to draw for you so you will have a wonderful permanent record. Of course, quite how wonderful it is will depend on the drawing ability of the guests you have invited! If they aren’t very talented, you’ll have a wonderfully amusing permanent record, at the very least.</p>
                <p>All you need to do is write down a description of this memory in the box below, making sure you give your budding artists enough of an impression to inspire their artistic tendencies.</p>
                <p>Something like this:
                  The sun is setting on a Caribbean beach. Palm trees sway gently in the background. Two cocktails await us on a table next to the sun loungers. We are in our swimwear playing frisbee!
                </p>
              </div>
          </v-card-text>


        <div class="pa-4">
            <v-textarea
                v-model="memory"
                :rules="specialMemoryRules"
                label="Your Special Memory"
                counter="350"
                outlined
                @change="fieldValueChanged"
            />

            <div class="mt-8">
                    <v-btn
            :disabled="!valid || readOnlyMode"
            type="submit"
            color="green"
            class="mr-4"
        >
          Save
        </v-btn>
            <v-btn :disabled="readOnlyMode" @click="clear" class="mr-4">Clear</v-btn>
            <v-btn :disabled="!valid" @click="preview">Preview</v-btn>
            </div>
        </div>
    </v-card>

  </v-form>
</template>

<script>
import {form} from "@/mixins/form";

export default {
  name: "ChallengeK",
  mixins: [form],
  data: () => ({
    challengeId: "k",
  }),
  computed: {
    memory: {
      get: function () {
        return this.$store.state.order.activities[10].data.memory
      },
      set(value) {
        this.$store.commit("setActivity", {
          challengeId: this.challengeId,
          memory: value
        })
      }
    },
  }
}
</script>
