export const challenge = {
    props: {
        absentFriends: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        /*
        * // TODO: This is as yet unused. Just an idea to get activity index by searching for them by name rather than by
        *     directly accessing them by array index (which is very dependent on the activities array indices not changing)
        * */
        // activityIndex() {
        //     const activitiesArray =- this.$store.state.order.activities
        //     // Find the activity object matching its name property this challenge's ID.
        //     const activityObj = activitiesArray.find(item => item.name === this.challengeId)
        //     // Return the index of that object in the array.
        //     return activitiesArray.indexOf(activityObj)
        // },
        previewOptions() {
            return {absentFriends: this.absentFriends}
        }
    }
}
