<template>
  <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      @submit.prevent="submit(challengeId)"
      :disabled="readOnlyMode"
  >

    <v-card class="rounded-lg mb-8">
        <v-card-title>
            True or False?
        </v-card-title>
        <v-card-text>
            <div class="mb-4 subtitle-1">
                <strong>Why you may want to choose this activity</strong><br>
            </div>
            <div>
                <p>
                You will miss an opportunity if you don’t choose this activity. Now was that true or false? We’ll let you
                decide. For this one, you’ll need to think of 3 fun or bizarre facts about yourselves. We will then mix these up with
                3 falsehoods, which you can either write yourselves or choose from a list we will provide (some are tame, some
                are wild!). Your guests then have to decide which of the 6 facts on the page are true, and which are false.
                </p>

            <img
              class="mb-4"
              style="max-width: 600px; width: 100%;"
              src="@/assets/img/activity_j_true_or_false.jpg"
              alt="Image shape"
            />

            </div>
        </v-card-text>
    </v-card>

    <v-card>
          <v-card-text>
              <div class="mb-4 subtitle-1">
                  <strong>How to build this activity</strong><br>
              </div>
              <div class="text-primary">
                <p>What you need to do for this activity is think of 3 true statements about yourselves that no-one else knows
                  about. The statements don’t have to be anything amazing, just different, funny, weird or quirky. The facts
                  can be about both of you or just one. You then need to create up to 3 falsehoods or choose some from the
                  list of suggestions we have provided for you.</p>
                <p>We will then mix up the 6 questions and present them to your guests to try and work out which are true and
                  which are false.</p>
                <p>We also need a photo and caption for this page.</p>
              </div>
          </v-card-text>

      <div class="pa-4">

              <p>
                The image below is the shape of the photo required.
              </p>
              <img
                class="mb-4"
                style="max-width: 350px; width: 100%;"
                src="@/assets/img/photo_sizing_w92mm_h85mm.jpg"
                alt="Image shape"
              />      

        <image-upload
            ref="imageUpload"
            sourceType="challenge"
            :sourceId=challengeId
        />

        <v-text-field
            v-model="caption"
            :rules="captionRules"
            label="Write a caption for this photo"
            counter="65"
            outlined
            class="mt-8"
            @change="fieldValueChanged"
        >
        </v-text-field>

        <h3 class="mb-6">Facts</h3>

        <ol>
          <li v-for="fact in facts">
            <v-text-field
                v-model="fact.text"
                :rules="statementRules"
                label="Fact"
                counter="90"
                outlined
                @change="fieldValueChanged"
            >
            </v-text-field>
          </li>
        </ol>

        <h3 class="mb-6">Falsehoods</h3>

        <ol>
          <li v-for="falsehood in falsehoods">
            <v-text-field
                v-model="falsehood.text"
                :rules="statementRules"
                label="Falsehood"
                counter="90"
                outlined
                @change="fieldValueChanged"
            >
            </v-text-field>
            <v-select
                v-model="falsehood.text"
                :items=presetFalsehoods
                label="Suggestions"
                dense
                outlined
                @change="fieldValueChanged"
            ></v-select>
          </li>
        </ol>

        <div class="mt-8">
                  <v-btn
            :disabled="!valid || readOnlyMode"
            type="submit"
            color="green"
            class="mr-4"
        >
          Save
        </v-btn>
          <v-btn :disabled="readOnlyMode" @click="clear" class="mr-4">Clear</v-btn>
          <v-btn :disabled="!valid" @click="preview(previewOptions)">Preview</v-btn>
        </div>
      </div>
    </v-card>

  </v-form>
</template>

<script>
import {challenge} from "@/mixins/challenge";
import {form} from "@/mixins/form";
import ImageUpload from "@/components/ImageUpload";

export default {
  name: "ChallengeJ",
  components: {ImageUpload},
  mixins: [challenge, form],
  data: () => ({
    challengeId: "j",
    selectedChallenge: {},
  }),
  computed: {
    caption: {
      get: function () {
        return this.$store.state.order.activities[9].data.caption
      },
      set(value) {
        this.$store.commit("setActivity", {
          challengeId: this.challengeId,
          caption: value
        })
      }
    },
    facts() {
      return this.$store.state.order.activities[9].data.statements.facts
    },
    falsehoods() {
      return this.$store.state.order.activities[9].data.statements.falsehoods
    },
    presetFalsehoods() {
      // Initial static list of suggestions.
      let suggestions = [
        "We once met Adele in a pub.",
        "Some years before we met, we both visited the Eiffel Tower on the same day!",
        "Our favourite teachers at school were both called Mrs Walker!",
      ]
      // Templates for dynamic suggestions that will use the couple's names.
      const suggestionTemplates = [
          "X has 11 toes.",
          "X can say 'Two beers please' in ten different languages!",
          "X has no middle name.",
          "X's hairdresser once lived next door to the Queen's dressmaker's assistant.",
          "X got to Grade 8 on the piano.",
      ]
      suggestionTemplates.forEach(template => {
        // Strip initial `X` from beginning of template string.
        const suffix = template.substring(1)
        // Get couple names from $store.
        const name_1 = this.$store.state.order.generalData.name_1
        const name_2 = this.$store.state.order.generalData.name_2
        // Add dynamic suggestions if each name field has a value.
        if (name_1.length) {
          suggestions.push(name_1 + suffix)
        }
        if (name_2.length) {
          suggestions.push(name_2 + suffix)
        }
      })
      return suggestions
    }
  },
  watch: {
    facts: {
      handler: function (value) {
        this.$store.commit("setActivity", {
          challengeId: this.challengeId,
          facts: value
        })
      },
      deep: true
    },
    falsehoods: {
      handler: function (value) {
        this.$store.commit("setActivity", {
          challengeId: this.challengeId,
          falsehoods: value
        })
      },
      deep: true
    },
  },
}
</script>
