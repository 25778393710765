<template>
  <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      @submit.prevent="submit(challengeId)"
      :disabled="readOnlyMode"
  >

    <v-card class="rounded-lg mb-8">
        <v-card-title>
            Where Were We?
        </v-card-title>
        <v-card-text>
            <div class="mb-4 subtitle-1">
                <strong>Why you may want to choose this activity</strong><br>
            </div>
            <div>
                <p>
                Who needs Insta, we’ve got Ana. Yes, this is an anagram game. The anagrams though will be of places you have visited or are important to you. Once you’ve thought of 6 places, your answers will be converted to anagrams which the guests must then unravel. Did your colleagues know you’d been to Route Amooling (Outer Mongolia!)? They do now! You will also be able to give a small hint to help.
                </p>

            <img
              class="mb-4"
              style="max-width: 600px; width: 100%;"
              src="@/assets/img/activity_m_where_were_we.jpg"
              alt="Image shape"
            />

            </div>
        </v-card-text>
    </v-card>

    <v-card>
          <v-card-text>
              <div class="mb-4 subtitle-1">
                  <strong>How to build this activity</strong><br>
              </div>
              <div class="text-primary">
                <p>For this activity you need to think of 6 places that are important to you or link to you as a couple.  They can be cities, countries, hotels, beaches, house names, whatever you like. In fact, the more variety in your places the better fun for your guests to work them out. They can be locations with single words or multiple words. Simply type the name in the box, and our clever system will convert your place name into an anagram. All you need to do then is help your guests a small hint like country, or city or holiday destination etc.</p>
                <p>You also need to upload a photo and caption for this activity.</p>
              </div>
          </v-card-text>

        <div class="pa-4">

              <p>
                The image below is the shape of the photo required.
              </p>
              <img
                class="mb-4"
                style="max-width: 350px; width: 100%;"
                src="@/assets/img/photo_sizing_w92mm_h85mm.jpg"
                alt="Image shape"
              />      

            <image-upload
                ref="imageUpload"
                sourceType="challenge"
                :sourceId=challengeId
            />

            <v-text-field
                v-model="caption"
                :rules="captionRules"
                label="Write a caption for this photo"
                outlined
                class="mt-8"
                @change="fieldValueChanged"
            >
            </v-text-field>

            <ol>
            <li v-for="anagram in anagrams">
                <v-text-field
                    v-model="anagram.answer"
                    :rules="anagramAnswerRules"
                    label="Answer"
                    counter="20"
                    outlined
                    @change="fieldValueChanged"
                />
                <v-chip
                    v-if="anagram.answer"
                    class="mb-8"
                >
                {{ anagram.answer | generate(anagram) }}
                </v-chip>
                <v-text-field
                    v-model="anagram.hint"
                    :rules="hintRules"
                    label="Hint"
                    counter="30"
                    outlined
                    @change="fieldValueChanged"
                />
            </li>
            </ol>

            <div class="mt-8">
                        <v-btn
            :disabled="!valid || readOnlyMode"
            type="submit"
            color="green"
            class="mr-4"
        >
          Save
        </v-btn>
                <v-btn :disabled="readOnlyMode" @click="clear" class="mr-4">Clear</v-btn>
                <v-btn :disabled="!valid" @click="preview(previewOptions)">Preview</v-btn>
            </div>
        </div>
    </v-card>

  </v-form>
</template>

<script>
import {challenge} from "@/mixins/challenge";
import {form} from "@/mixins/form";
import ImageUpload from "@/components/ImageUpload";
import generateAnagram from "@/modules/generateAnagram";

export default {
  name: "ChallengeM",
  components: { ImageUpload },
  mixins: [challenge, form],
  data: () => ({
    challengeId: "m",
    selectedChallenge: {}
  }),
  computed: {
    caption: {
      get: function () {
        return this.$store.state.order.activities[12].data.caption
      },
      set(value) {
        this.$store.commit("setActivity", {
          challengeId: this.challengeId,
          caption: value
        })
      }
    },
    anagrams: {
      get: function () {
        return this.$store.state.order.activities[12].data.anagrams
      },
      // Note no corresponding set(). See `anagrams` watch option.
    },
  },
  filters: {
    generate: function (value, anagram) {
      const generatedAnagram = generateAnagram(value)
      anagram.generated = generatedAnagram.anagram
      anagram.charCount = generatedAnagram.charCount
      return generatedAnagram.anagram
    }
  },
  watch: {
    anagrams: {
      handler: function (value) {
        this.$store.commit("setActivity", {
          challengeId: this.challengeId,
          anagrams: value
        })
      },
      deep: true
    }
  },
}
</script>
