<template>
  <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      @submit.prevent="submit(challengeId)"
      :disabled="readOnlyMode"
  >


    <v-card class="rounded-lg mb-8">
        <v-card-title>
            Round The World Wedding Quiz
        </v-card-title>
        <v-card-text>
            <div class="mb-4 subtitle-1">
                <strong>Why you may want to choose this activity</strong><br>
            </div>
            <div>
                <p>
                This quiz is based on wedding traditions around the world, and trust us, they are not dull! This activity is ready to go and requires no
                personalisation whatsoever. It’s multiple choice with 12 questions all based on wedding traditions from around
                the world. If you or your guests think they have been to strange weddings before, think again!
                </p>
            </div>

            <img
              class="mb-4"
              style="max-width: 600px; width: 100%;"
              src="@/assets/img/activity_c_round_the_world_wedding_quiz.jpg"
              alt="Image shape"
            />
            
        </v-card-text>
    </v-card>

    <v-card>
        <v-card-text>
            <div class="mb-4 subtitle-1">
                <strong>How to build this activity</strong><br>
            </div>
            <div class="text-primary">
              <p>
              No personalisation is required for this activity whatsoever, not even a photo or caption! Put your feet up for
              this one.
              </p>
            </div>
        </v-card-text>

      <div class="mt-8 pa-4">
                <v-btn
            :disabled="!valid || readOnlyMode"
            type="submit"
            color="green"
            class="mr-4"
        >
          Save
        </v-btn>
        <v-btn :disabled="!valid" @click="preview(previewOptions)">Preview</v-btn>
      </div>
    </v-card>


  </v-form>
</template>

<script>
import {challenge} from "@/mixins/challenge";
import {form} from "@/mixins/form";

export default {
  name: "ChallengeC",
  mixins: [challenge, form],
  data: () => ({
    challengeId: "c",
  }),
}
</script>
