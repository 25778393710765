<template>
  <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      @submit.prevent="submit(challengeId)"
      :disabled="readOnlyMode"
  >


    <v-card class="rounded-lg mb-8">
        <v-card-title>
            Wedding Speech Bingo
        </v-card-title>
        <v-card-text>
            <div class="mb-4 subtitle-1">
                <strong>Why you may want to choose this activity</strong><br>
            </div>
            <div>
                <p>
                Cue hilarious results, we have created a bingo card for the page which contains popular words and phrases used in wedding speeches. As guests listen to the speeches, they can cross off any that are said by the speakers. The question is will anyone get a line or a full house? N.B. As guests will need to shout out when they get a line or full house, we recommend you do not choose this activity if you do not want to risk the speeches being interrupted at a crucial or sentimental moment! This activity requires no effort from you - not even a photo or caption. Eyes down!
                </p>

            <img
              class="mb-4"
              style="max-width: 600px; width: 100%;"
              src="@/assets/img/activity_i_wedding_speech_bingo.jpg"
              alt="Image shape"
            />

            </div>
        </v-card-text>
    </v-card>

    <v-card>
          <v-card-text>
              <div class="mb-4 subtitle-1">
                  <strong>How to build this activity</strong><br>
              </div>
              <div class="text-primary">
                <p>
			          We don’t need anything from you for this, not even a photo and caption. Whether you are tempted to think of ways to include some of the phrases in your speeches, well, that’s up to you!
		            </p>
              </div>
          </v-card-text>


        <div class="pa-4 mt-8">
                <v-btn
            :disabled="!valid || readOnlyMode"
            type="submit"
            color="green"
            class="mr-4"
        >
          Save
        </v-btn>
        <v-btn :disabled="!valid" @click="preview">Preview</v-btn>
        </div>
    </v-card>

  </v-form>
</template>

<script>
import {form} from "@/mixins/form";

export default {
  name: "ChallengeI",
  mixins: [form],
  data: () => ({
    challengeId: "i",
  }),
}
</script>
