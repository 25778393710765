<template>
  <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      @submit.prevent="submit(challengeId)"
      :disabled="readOnlyMode"
  >

    <v-card class="rounded-lg mb-8">
        <v-card-title>
            Words of Wisdom
        </v-card-title>
        <v-card-text>
            <div class="mb-4 subtitle-1">
                <strong>Why you may want to choose this activity</strong><br>
            </div>
            <div>
                <p>
                Also known as mad libs... this improvising little activity gives your guests a piece of text which requires them to fill in blanks to create sentences. Trust us, it’s more exciting than it sounds! The result of their efforts should be a fun source of advice to help you have a long and happy marriage. If you choose this activity, encourage your guests to send or show you their creations!
                </p>

            <img
              class="mb-4"
              style="max-width: 600px; width: 100%;"
              src="@/assets/img/activity_g_words_of_wisdom.jpg"
              alt="Image shape"
            />

            </div>
        </v-card-text>
    </v-card>

    <v-card>
          <v-card-text>
              <div class="mb-4 subtitle-1">
                  <strong>How to build this activity</strong><br>
              </div>
              <div class="text-primary">
                    <p>
    	              Hopefully you are intrigued at the thought of the wise words (or otherwise!) you will soon be receiving! In the meantime, though, no personalisation is required for this activity, including no photo and no caption.
		            </p>
              </div>
          </v-card-text>

        <div class="pa-4">
            <div class="mt-8">
                    <v-btn
            :disabled="!valid || readOnlyMode"
            type="submit"
            color="green"
            class="mr-4"
        >
          Save
        </v-btn>
            <v-btn :disabled="!valid" @click="preview">Preview</v-btn>
            </div>
        </div>
    </v-card>

  </v-form>
</template>

<script>
import {form} from "@/mixins/form";

export default {
  name: "ChallengeG",
  mixins: [form],
  data: () => ({
    challengeId: "g",
  }),
}
</script>
