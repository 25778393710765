<template>
  <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      @submit.prevent="submit(challengeId)"
      :disabled="readOnlyMode"
  >

    <v-card class="rounded-lg mb-8">
        <v-card-title>
            Say What You See
        </v-card-title>
        <v-card-text>
            <div class="mb-4 subtitle-1">
                <strong>Why you may want to choose this activity</strong><br>
            </div>
            <div>
                <p>
                This activity does what it says on the tin. Clichés aside though, this cheeky challenge is made up of 6 visual puzzles which depict a word, phrase or well-known saying linked to weddings. A certain amount of lateral thinking is required, and this activity will certainly generate a huge amount of debate around the tables. There is no personalisation needed, aside from uploading a photo and caption. Let the brain-teasing begin!
                </p>

            <img
              class="mb-4"
              style="max-width: 600px; width: 100%;"
              src="@/assets/img/activity_e_say_what_you_see.jpg"
              alt="Image shape"
            />

            </div>
        </v-card-text>
    </v-card>

    <v-card>
          <v-card-text>
              <div class="mb-4 subtitle-1">
                  <strong>How to build this activity</strong><br>
              </div>
              <div class="text-primary">
                    <p>
			              Good choice with this one, brain-teasers always get people thinking and are such a great little ice-breaker. For this activity, all we need are a photo and a caption.
			            </p>
              </div>
          </v-card-text>

            <div class="pa-4">

              <p>
                The image below is the shape of the photo required.
              </p>
              <img
                class="mb-4"
                style="max-width: 350px; width: 100%;"
                src="@/assets/img/photo_sizing_w92mm_h80mm.jpg"
                alt="Image shape"
              />  

                <image-upload
                    ref="imageUpload"
                    sourceType="challenge"
                    :sourceId=challengeId
                />

                <v-text-field
                    v-model="caption"
                    :rules="captionRules"
                    label="Write a caption for this photo"
                    counter="65"
                    outlined
                    class="mt-8"
                    @change="fieldValueChanged"
                />

                <div class="mt-8">
                        <v-btn
            :disabled="!valid || readOnlyMode"
            type="submit"
            color="green"
            class="mr-4"
        >
          Save
        </v-btn>
                <v-btn :disabled="readOnlyMode" @click="clear" class="mr-4">Clear</v-btn>
                <v-btn :disabled="!valid" @click="preview(previewOptions)">Preview</v-btn>
                </div>
            </div>
        </v-card>

  </v-form>
</template>

<script>
import ImageUpload from "@/components/ImageUpload";
import {challenge} from "@/mixins/challenge";
import {form} from "@/mixins/form";

export default {
  name: "ChallengeE",
  components: { ImageUpload },
  mixins: [challenge, form],
  data: () => ({
    challengeId: "e",
  }),
  computed: {
    caption: {
      get: function () {
        return this.$store.state.order.activities[4].data.caption
      },
      set(value) {
        this.$store.commit("setActivity", {
          challengeId: this.challengeId,
          caption: value
        })
      }
    },
  }
}
</script>
