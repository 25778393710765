<template>
  <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      @submit.prevent="submit(challengeId)"
      :disabled="readOnlyMode"
  >

    <v-card class="rounded-lg mb-8">
        <v-card-title>
            Love Letters
        </v-card-title>
        <v-card-text>
            <div class="mb-4 subtitle-1">
                <strong>Why you may want to choose this activity</strong><br>
            </div>
            <div>
                <p>
                  Secret messages, a twist on a classic puzzle, and even a riddle. This puzzling activity is sure to have your guests swapping 
                  answers and sneaking peeks - a wordsearch like no other. The words to be found in the heart-shaped grid are all linked to weddings 
                  and marriage, and the remaining letters spell out a tricky riddle that will have the guests chatting, conferring and hopefully not cheating!
                </p>

            <img
              class="mb-4"
              style="max-width: 600px; width: 100%;"
              src="@/assets/img/activity_a_love_letters.jpg"
              alt="Image shape"
            />

            </div>
        </v-card-text>
    </v-card>

    <v-card>
        <v-card-text>
            <div class="mb-4 subtitle-1">
                <strong>How to build this activity</strong><br>
            </div>
            <div class="text-primary">
                This puzzling activity is ready to go and requires no personalisation from you other than a photo and a caption.
                <br>
                The image below is the shape of the photo required.
            </div>
        </v-card-text>

        <div class="pa-4">
        
            <img
              class="mb-4"
              style="max-width: 350px; width: 100%;"
              src="@/assets/img/photo_sizing_w92mm_h85mm.jpg"
              alt="Image shape"
            />
           
            <image-upload
                ref="imageUpload"
                sourceType="challenge"
                :sourceId=challengeId
            />

            <v-text-field
                v-model="caption"
                :rules="captionRules"
                label="Write a caption for this photo"
                counter="65"
                outlined
                class="mt-8"
                @change="fieldValueChanged"
            >
            </v-text-field>

            <div class="mt-8">
                        <v-btn
            :disabled="!valid || readOnlyMode"
            type="submit"
            color="green"
            class="mr-4"
        >
          Save
        </v-btn>
                <v-btn :disabled="readOnlyMode" @click="clear" class="mr-4">Clear</v-btn>
                <v-btn :disabled="!valid" @click="preview(previewOptions)">Preview</v-btn>
            </div>
        </div>
    </v-card>

  </v-form>
</template>

<script>
import ImageUpload from "@/components/ImageUpload";
import {challenge} from "@/mixins/challenge";
import {form} from "@/mixins/form";

export default {
  name: "ChallengeA",
  components: { ImageUpload },
  mixins: [challenge, form],
  data: () => ({
    challengeId: "a",
  }),
  computed: {
    caption: {
      get: function () {
        return this.$store.state.order.activities[0].data.caption
      },
      set(value) {
        this.$store.commit("setActivity", {
          challengeId: this.challengeId,
          caption: value
        })
      }
    },
  }
}
</script>
