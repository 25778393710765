<template>
  <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      @submit.prevent="submit(challengeId)"
      :disabled="readOnlyMode"
  >

    <v-card class="rounded-lg mb-8">
        <v-card-title>
            The Limerick
        </v-card-title>
        <v-card-text>
            <div class="mb-4 subtitle-1">
                <strong>Why you may want to choose this activity</strong><br>
            </div>
            <div>
                <p>
                  If you think that poems are fun,<br>
                  This activity should be one,<br>
                  Used to find out which guest,<br>
                  Can write rhymes the best<br>
                  And is an expert with a pun!<br>
                </p>
                <p>
                    Your guests are given the opening line of a limerick and must use their wit, imagination and creativity to complete the remaining lines. You choose from a number of possible opening lines that all end with words with rhyming potential, no silver or oranges here! If you choose this activity, encourage your guests to send or show you their creations!
                </p>

            <img
              class="mb-4"
              style="max-width: 600px; width: 100%;"
              src="@/assets/img/activity_f_limerick.jpg"
              alt="Image shape"
            />
            </div>
        </v-card-text>
    </v-card>

    <v-card>
          <v-card-text>
              <div class="mb-4 subtitle-1">
                  <strong>How to build this activity</strong><br>
              </div>
              <div class="text-primary">
                <p>All you need to do for this activity is choose the first line of the limerick to be inserted into the booklet, and then upload a photo and a caption.</p>
                <p>A limerick is more complicated than it looks and requires a clever first line to ensure that it reads correctly, and a last word on that line with a good range of possible rhyming words. This is why we have a selection for you to choose from rather than the pressure of having to think of one yourself.  </p>
              </div>
          </v-card-text>



        <div class="pa-4">

            <v-select
                v-model="limerickFirstLine"
                :items=limerickLines
                label="Choose your first line"
                dense
                outlined
                @change="fieldValueChanged"
            ></v-select>

              <p>
                The image below is the shape of the photo required.
              </p>
              <img
                class="mb-4"
                style="max-width: 350px; width: 100%;"
                src="@/assets/img/photo_sizing_w92mm_h57mm.jpg"
                alt="Image shape"
              />             

            <image-upload
                ref="imageUpload"
                sourceType="challenge"
                :sourceId=challengeId
            />

            <v-text-field
                v-model="caption"
                :rules="captionRules"
                label="Write a caption for this photo"
                counter="65"
                outlined
                class="mt-8"
                @change="fieldValueChanged"
            >
            </v-text-field>

            <div class="mt-8">
                    <v-btn
            :disabled="!valid || readOnlyMode"
            type="submit"
            color="green"
            class="mr-4"
        >
          Save
        </v-btn>
            <v-btn :disabled="readOnlyMode" @click="clear" class="mr-4">Clear</v-btn>
            <v-btn :disabled="!valid" @click="preview">Preview</v-btn>
            </div>
        </div>
    </v-card>

  </v-form>
</template>

<script>
import ImageUpload from "@/components/ImageUpload";
import {form} from "@/mixins/form";

export default {
  name: "ChallengeF",
  components: { ImageUpload },
  mixins: [form],
  data: () => ({
    challengeId: "f",
    limerickLines: [
        "On the day the couple were wed",
        "When you love with all your heart",
        "As they shared a loving embrace",
        "When the happy couple first met",
        "On the couple’s special day"
    ],
  }),
  computed: {
    caption: {
      get: function () {
        return this.$store.state.order.activities[5].data.caption
      },
      set(value) {
        this.$store.commit("setActivity", {
          challengeId: this.challengeId,
          caption: value
        })
      }
    },
    limerickFirstLine: {
      get: function () {
        return this.$store.state.order.activities[5].data.limerick
      },
      set(value) {
        this.$store.commit("setActivity", {
          challengeId: this.challengeId,
          limerick: value
        })
      }
    },
  }
}
</script>
